import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          background: "#FFFFFF",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        },
      },
    },
    MuiAccordion: {
      root: {
        "&:before": {
          opacity: 0,
        },
      },
    },

    MuiAccordionSummary: {
      content: {
        margin: "0.3rem 0 0.3rem 0",
      },
    },

    MuiRating: {
      root: {
        color: "#2CB646",
      },
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: {
      main: "#2CB646",
      light: "#2CB646",
      dark: "#2CB646",
    },
    secondary: {
      main: "#FFFFFF",
      light: "#FFFFFF",
      dark: "#CCCCCC",
    },
    error: {
      main: "#BDBDBD",
      light: "#FF4F30",
      dark: "#930000",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  breakpoints: {
    values: {
      xs: 280.05 /* TODO: Change to 0 when TestimonialsCardGrid is redone */,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
